<!-- @format -->

<script>
import { BTabs, BTab, BTable, BForm, BFormGroup } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { getCurrentInstance, nextTick, onMounted, ref } from '@vue/composition-api'

// Commission Details
const zhifujiluClumn = [
  {
    label: 'Member',
    key: 'user',
  },
  {
    label: 'Company',
    key: 'bx_company',
  },
  {
    label: 'Product',
    key: 'product.title',
  },
  {
    label: 'Policy No.',
    key: 'number.number',
  },
  {
    label: 'Insured',
    key: 'protect_user',
  },
  {
    label: 'Payment Date',
    key: 'pay_time',
  },
  {
    label: 'Commission Type',
    key: 'number.money',
  },
  {
    label: 'All TP',
    key: 'order_tp',
    formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
  // {
  //   label: 'Payout rate',
  //   key: 'rate',
  //   formatter: (value, key, item) => {
  //     if (item?.user?.is_show) {
  //       return `${value}%`
  //     }
  //     return '--You are unable to view--'
  //   },
  // },
  {
    label: 'Personal paid',
    key: 'personal_pay_commission',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
  {
    label: 'Personal TP Balance',
    key: 'personal_unpay_tp',
    formatter: value => `${value}`,
  },
  {
    label: 'Personal Balance',
    key: 'personal_unpay_commission',
    formatter: value => `${value}`,
  },
]

// Personal Commission
const gerenyj = [
  {
    label: 'Name',
    key: 'real_name',
  },
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Level',
    key: 'level',
  },
  {
    label: 'Total TP',
    key: 'all_tp',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  // {
  //   label: 'Payout rate',
  //   key: 'rate',
  //   formatter: (value, key, item) => {
  //     if (item?.user?.is_show) {
  //       return `${value}%`
  //     }
  //     return '--You are unable to view--'
  //   },
  // },
  {
    label: 'Total Individual Level Commission',
    key: 'income',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
  {
    label: 'TP Paid',
    key: 'pay_tp',
    formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
  {
    label: 'Unpaid TP',
    key: 'not_pay_tp',
    formatter: value => `${value}`,
  },
  {
    label: 'Commission Paid',
    key: 'pay_income',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
  {
    label: 'Unpaid commission',
    key: 'not_pay_income',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
]

const gerenyj2 = [
  {
    key: 'real_name',
    label: 'Name',
  },
  {
    key: 'id',
    label: 'Serial Number',
  },
  {
    key: 'level',
    label: 'Level',
  },
  {
    key: 'pay_tp',
    label: 'Payment TP',
    formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
  {
    key: 'cate_name',
    label: 'Type',
  },
  {
    key: 'pay_time',
    label: 'Payment Date',
  },
  {
    key: 'income',
    label: 'Personal Commissions Paid',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
]

// Level Commission
const cengjiYongj = [
  {
    label: 'Name',
    key: 'real_name',
  },
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Level',
    key: 'level',
  },
  {
    label: 'Total TP',
    key: 'all_tp',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  {
    label: ' Total Level Commission',
    key: 'user_level_gap',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  {
    label: 'TP Paid',
    key: 'pay_tp',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  {
    label: 'Unpaid TP',
    key: 'not_pay_tp',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  {
    label: 'Commission Paid',
    key: 'pay_income',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  {
    label: 'Unpaid commission',
    key: 'not_pay_income',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
]

// Override Commission
const tuanDuiYongj = [
  {
    label: 'Name',
    key: 'real_name',
  },
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Level',
    key: 'level',
  },
  {
    label: 'Total TP',
    key: 'all_tp',
    formatter: (value, key, item) => {
      if (item?.user?.is_show) {
        return `${value}%`
      }
      return ''
    },
  },
  {
    label: 'Total Override Commission',
    key: 'total_team_commission',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
  {
    label: 'TP Paid',
    key: 'pay_tp',
    formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
  {
    label: 'Unpaid TP',
    key: 'not_pay_tp',
    formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
  {
    label: 'Paid Override Commission',
    key: 'pay_team_commission',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
  {
    label: 'Payable Override Commission',
    key: 'unpay_team_commission',
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
]

export default {
  components: {
    BTabs,
    BTab,
    BTable,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      zhifujiluClumn,
      gerenyj,
      gerenyj2,
      cengjiYongj,
      tuanDuiYongj,
      active: 0,
    }
  },

  setup() {
    const { proxy } = getCurrentInstance()

    // y佣金 Details--------------------------------------
    const getZhifujiluDataArray = ref([])
    const getZhifujiluDataForm = ref([])
    const getZhifujiluData = () => {
      axiosIns.get(`/user-income/detail?order_id=${proxy.$route.query.id}`).then(res => {
        getZhifujiluDataArray.value = res.data?.list
        getZhifujiluDataForm.value = res.data?.pay
      })
    }

    // Personal Commission Details--------------------------------------
    const gerenyjDataArray = ref([])
    const genrenyjDataForm = ref([])
    const gerenyjData = () => {
      axiosIns.get(`/user-income/personal?order_id=${proxy.$route.query.id}`).then(res => {
        gerenyjDataArray.value = res.data.list
        genrenyjDataForm.value = res.data.pay
      })
    }

    // Level Commission Details--------------------------------------
    const cengjiYongjDataArray = ref([])
    const cengjiYongjDataForm = ref([])
    const cengjiYongjData = () => {
      axiosIns.get(`/user-income/level?order_id=${proxy.$route.query.id}`).then(res => {
        cengjiYongjDataArray.value = res.data.list
        cengjiYongjDataForm.value = res.data.pay
      })
    }

    // Override Commission Details--------------------------------------
    const tuanDuiYongjDataArray = ref([])
    const tuanDuiYongjDataForm = ref([])
    const tuanDuiYongjData = () => {
      axiosIns.get(`/user-income/team?order_id=${proxy.$route.query.id}`).then(res => {
        tuanDuiYongjDataArray.value = res.data.list
        tuanDuiYongjDataForm.value = res.data.pay
      })
    }
    onMounted(() => {
      nextTick(() => {
        getZhifujiluData()

        gerenyjData()

        cengjiYongjData()

        tuanDuiYongjData()
      })
    })

    return {
      getZhifujiluDataArray,
      getZhifujiluDataForm,
      gerenyjDataArray,
      genrenyjDataForm,
      cengjiYongjDataArray,
      cengjiYongjDataForm,
      tuanDuiYongjDataArray,
      tuanDuiYongjDataForm,
    }
  },
}
</script>

<template>
  <div>
    <b-tabs card v-model="active">
      <b-tab title="Commission Details">
        <b-card>
          <b-table :fields="zhifujiluClumn" bordered :items="getZhifujiluDataArray">
            <template #cell(user)="data">
              <div>{{ data.value.real_name }}</div>
              <div>{{ data.value.id }}</div>
              <div>{{ data.value.level }}</div>
            </template>
          </b-table>
        </b-card>

        <b-card title="Payment Info">
          <b-table :fields="gerenyj2" bordered :items="getZhifujiluDataForm" />
        </b-card>
      </b-tab>
      <b-tab title="Personal Commission">
        <b-card title="Personal Commission Info">
          <b-table :fields="gerenyj" bordered :items="gerenyjDataArray" />
        </b-card>

        <b-card title="Payment Info">
          <b-table :fields="gerenyj2" bordered :items="genrenyjDataForm" />
        </b-card>
      </b-tab>
      <b-tab title="Level Commission">
        <b-card title="Level Commission Info">
          <b-table :fields="cengjiYongj" bordered :items="cengjiYongjDataArray" />
        </b-card>

        <b-card title="Payment Info">
          <b-table :fields="gerenyj2" bordered :items="cengjiYongjDataForm" />
        </b-card>
      </b-tab>
      <b-tab title="Override Commission">
        <b-card title="Override Commission Info">
          <b-table :fields="tuanDuiYongj" bordered :items="tuanDuiYongjDataArray" />
        </b-card>

        <b-card title="Payment Info">
          <b-table :fields="gerenyj2" bordered :items="tuanDuiYongjDataForm" />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 576px) {
    .table-wrap, .card-body {
      overflow: auto;
    }
  }
</style>
